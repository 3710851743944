export const variables = {
  API_URL: "https://xxkayan.duckdns.org/api",
  DOCS_URL: "https://xxkayan.duckdns.org/Docs",
  //API_URL: "https://localhost:7119/api",
  //DOCS_URL: "https://localhost:7119/Docs",
  LOGIN_ERROR_MSG: "Wrong Email or Password !",
  UPLOAD_FILE_ERROR_MSG:
    "Please make sure upload crrect file, the file not uploaded !",
  INSERT_BALANCESHEET_ERROR_MSG:
    "Please check the data and make sure file uploaded",
};
