import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BalanceSheets from "./balanceSheets";
import Insert from "./insert";
import Topbar from "./components/Topbar";
import Footerbar from "./components/Footerbar";
import Summary from "./summary";
import Login from "./login";
import Logout from "./logout";
import Home from "./home";
import Form from "./form";
import FormsList from "./formsList";
import { useState, useEffect } from "react";
import { getCurrentUser } from "./services/authenticationService";

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    setUser(getCurrentUser());
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer theme="dark" />
      <Topbar user={user} />
      <main>
        <Routes>
          <Route path="/balanceSheets" element={<BalanceSheets />} />
          <Route path="/insert" element={<Insert user={user} />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/form/:id" element={<Form />} />
          <Route path="/FormsList" element={<FormsList />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </main>
      <Footerbar />
    </BrowserRouter>
  );
}

export default App;
