import React, { useState, useEffect, useMemo } from "react";
import Joi from "joi-browser";

import useValidate from "./hooks/useValidate";
import { login, logout } from "./services/authenticationService";
import { variables } from "./config.js";

function Login() {
  const [
    userData,
    errors,
    canSubmit,
    setUserData,
    setSchema,
    validate,
    onChange,
  ] = useValidate(
    {
      email: "",
      password: "",
    },
    false,
    false
  );
  const [loginError, setLoginError] = useState({});

  const schema = useMemo(() => {
    return {
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .min(5)
        .max(50)
        .required(),
      password: Joi.string().min(6).max(20).required(),
    };
  }, []);

  useEffect(() => {
    setSchema(schema);
    logout();
  }, [schema, setSchema]);

  const handleSubmit = async (event) => {
    if (validate(userData)) {
      try {
        await login(userData.email, userData.password);
        window.location = "/";
      } catch (ex) {
        console.log(ex); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        if (ex.response && ex.response.status === 401) {
          const loginErrorData = { ...loginError };
          loginErrorData.msg = variables.LOGIN_ERROR_MSG;
          setLoginError(loginErrorData);
        }
      }
    }
  };

  const handleChange = (event) => {
    setLoginError({});
    const { name, value } = event.target;
    let xuserData = { ...userData };
    xuserData[name] = value;
    setUserData(xuserData);
    onChange(event);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row m-2">
          <div className="col-sm-12">
            {/* <!-- Start Card  --> */}
            <div className="card">
              <h5 className="card-header fw-bold">تسجيل الدخول</h5>
              <div className="card-body">
                <div className="form-group m-2">
                  <label
                    htmlFor="email"
                    className="form-label fw-bold text-primary"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="email"
                    className="form-control"
                    value={userData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="alert alert-danger m-1" role="alert">
                      {errors.email}
                    </div>
                  )}
                </div>

                <div className="form-group m-2">
                  <label
                    htmlFor="password"
                    className="form-label fw-bold text-primary"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    id="password"
                    className="form-control"
                    value={userData.password}
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <div className="alert alert-danger m-1" role="alert">
                      {errors.password}
                    </div>
                  )}
                </div>
                <div className="form-group m-2 ">
                  {loginError.msg && (
                    <div className="alert alert-danger m-2" role="alert">
                      {loginError.msg}
                    </div>
                  )}
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary"
                    disabled={!canSubmit}
                  >
                    تسجيل الدخول - Login
                  </button>
                </div>
              </div>
            </div>
            {/* <!-- End Card  --> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
