import React, { Component } from "react";
import { getBalanceSheetsDateNotDeposit } from "../services/balanceSheetsService";

export class WorkingDateNotDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      WorkingDateNotDepositList: null,
      SelectedWorkingDateNotDepositList: [],
      TotalAmonut: 0.0,
      Checked: false,
    };
  }

  async getWorkingDateNotDeposit() {
    try {
      const { data } = await getBalanceSheetsDateNotDeposit();
      this.setState({ WorkingDateNotDepositList: data });
    } catch (ex) {
      this.setState({ WorkingDateNotDepositList: null });
    }
  }

  componentDidMount() {
    this.getWorkingDateNotDeposit();
  }

  deleteWorkingDateNotDepositFromList(index) {
    const WorkingDateNotDepositList = [...this.state.WorkingDateNotDepositList];
    WorkingDateNotDepositList.splice(index, 1);
    this.setState({ WorkingDateNotDepositList });
  }

  addWorkingDateNotDepositToSelectedList(workingdate, amount) {
    this.setState({
      SelectedWorkingDateNotDepositList:
        this.state.SelectedWorkingDateNotDepositList.concat(workingdate),
    });
    this.setState({ TotalAmonut: this.state.TotalAmonut + amount });
  }

  addWorkingDateNotDeposit() {
    if (this.state.WorkingDateNotDepositList.length > 0) {
      this.addWorkingDateNotDepositToSelectedList(
        this.state.WorkingDateNotDepositList[0].date,
        this.state.WorkingDateNotDepositList[0].amount
      );
      this.deleteWorkingDateNotDepositFromList(0);
    } else alert("NO Date avalibale");
  }
  render() {
    return (
      <>
        {!this.state.Checked ? (
          // <!-- START Comments Section-->
          <div className="row">
            <div className="col-lg-4">
              <h5 className="mb-0 fw-bold">اليوميات الغير مودعه</h5>
              <p className="text-muted">
                اليوميات المغلقه ولم يتم ايداعها بالبنك حنى الان
              </p>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3">
                    {this.state.WorkingDateNotDepositList == null ? (
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : this.state.WorkingDateNotDepositList.length <= 0 ? (
                      <span className="badge text-bg-warning">
                        لا يوجد يوميات للإيداع
                      </span>
                    ) : (
                      <>
                        <ol className="list-group list-group-numbered">
                          {this.state.WorkingDateNotDepositList.map((item) => (
                            <li
                              key={item.date}
                              className="list-group-item d-flex justify-content-between align-items-start list-group-item-light"
                            >
                              <div className="ms-2 me-auto">
                                <div className="fw-bold">{item.date}</div>
                              </div>
                              <h4>
                                <span className="badge bg-success ">
                                  {item.amount}
                                </span>
                              </h4>
                            </li>
                          ))}
                        </ol>
                        <div className="d-grid gap-3 col-8 mx-auto">
                          <button
                            type="button"
                            className="btn btn-primary  mt-2"
                            onClick={() => this.addWorkingDateNotDeposit()}
                          >
                            أضافة اخر يوم
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : // <!-- END Comments Section -->
        null}{" "}
        {/* END this.state.Checked */}
        <hr className="my-5" />
        <div className="row">
          <div className="col-lg-4">
            <h5 className="mb-0 fw-bold">اليوميات المختارة</h5>
            <p className="text-muted">
              اليوميات المختارة للإيداع في هذا النموذج
            </p>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3">
                  <ol className="list-group list-group-numbered">
                    {this.state.SelectedWorkingDateNotDepositList.map(
                      (item) => (
                        <li
                          key={item}
                          className="list-group-item d-flex justify-content-between align-items-start list-group-item-info"
                        >
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">{item}</div>
                          </div>
                        </li>
                      )
                    )}
                  </ol>
                  <h4 className="my-2">
                    اجمالي المبلغ :{" "}
                    <span className="badge text-bg-success  border-3">
                      {" "}
                      {this.state.TotalAmonut}{" "}
                    </span>
                  </h4>
                  {this.state.SelectedWorkingDateNotDepositList != null &&
                  this.state.SelectedWorkingDateNotDepositList.length > 0 &&
                  !this.state.Checked ? (
                    <div className="d-grid gap-3 col-8 mx-auto">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={(evt) => {
                          this.setState({ Checked: true });
                          this.props.onChangeSelectedWorkingDateNotDepositList(
                            this.state.SelectedWorkingDateNotDepositList,
                            this.state.TotalAmonut
                          );
                        }}
                      >
                        تم التأكد من المبالغ
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WorkingDateNotDeposit;
