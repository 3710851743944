import { useEffect } from "react";

import { logout } from "./services/authenticationService";

function Logout() {
  useEffect(() => {
    logout();
    window.location = "/login";
  }, []);

  return null;
}

export default Logout;
