import _ from "lodash";
import http from "./httpService";
import { variables } from "../config.js";
import { getDateOnly } from "../utils/getDateTime";
import { removeMonthlyWeeklyProperty } from "../utils/removeMonthlyWeeklyProperty";
import { getCurrentUser } from "./authenticationService";
import { makeId } from "../utils/makeId";
const apiEndpoint = variables.API_URL + "/BalanceSheets";

export async function getBalanceSheets() {
  let res = await http.get(apiEndpoint);
  if (res) {
    _.forEach(res.data, function (value) {
      value.workingDate = getDateOnly(value.workingDate);
    });
  }
  return res;
}

export async function getStatisticsSales() {
  let res = await http.get(apiEndpoint + "/GetStatisticsSales");
  if (res) {
    res.data.dailyMAXDate = getDateOnly(res.data.dailyMAXDate);
    res.data.dailyMINDate = getDateOnly(res.data.dailyMINDate);
  }
  return res;
}

export async function getStatisticsExpenses() {
  let res = await http.get(apiEndpoint + "/GetStatisticsExpenses");
  if (res) {
    res.data.dailyMAXDate = getDateOnly(res.data.dailyMAXDate);
    res.data.dailyMINDate = getDateOnly(res.data.dailyMINDate);
  }
  return res;
}

export async function getStatisticsReturned() {
  let res = await http.get(apiEndpoint + "/GetStatisticsReturned");
  if (res) {
    res.data.dailyMAXDate = getDateOnly(res.data.dailyMAXDate);
    res.data.dailyMINDate = getDateOnly(res.data.dailyMINDate);
    removeMonthlyWeeklyProperty(res.data);
  }
  return res;
}

export async function getLastSalesExpensesReturned(
  dailyAVGSales,
  dailyAVGExpenses,
  dailyAVGReturned
) {
  let res = await http.get(apiEndpoint + "/GetLastSalesExpensesReturned");
  if (res) {
    _.forEach(res.data, function (value) {
      value.workingDate = getDateOnly(value.workingDate);
      value.dailyAVGSales = dailyAVGSales;
      value.dailyAVGExpenses = dailyAVGExpenses;
      value.dailyAVGReturned = dailyAVGReturned;
    });
  }
  return res;
}

export async function getStatisticsDeficit() {
  let res = await http.get(apiEndpoint + "/GetStatisticsDeficit");
  if (res) {
    res.data.dailyMAXDate = getDateOnly(res.data.dailyMAXDate);
    res.data.dailyMINDate = getDateOnly(res.data.dailyMINDate);
    removeMonthlyWeeklyProperty(res.data);
  }
  return res;
}

export async function getLastDeficit(dailyAVGDeficit) {
  let res = await http.get(apiEndpoint + "/GetLastDeficit");
  if (res) {
    _.forEach(res.data, function (value) {
      value.workingDate = getDateOnly(value.workingDate);
      value.dailyAVGDeficit = dailyAVGDeficit;
    });
  }
  return res;
}

export async function getStatisticsSurplus() {
  let res = await http.get(apiEndpoint + "/GetStatisticsSurplus");
  if (res) {
    res.data.dailyMAXDate = getDateOnly(res.data.dailyMAXDate);
    res.data.dailyMINDate = getDateOnly(res.data.dailyMINDate);
    removeMonthlyWeeklyProperty(res.data);
  }
  return res;
}

export async function getLastSurplus(dailyAVGSurplus) {
  let res = await http.get(apiEndpoint + "/GetLastSurplus");
  if (res) {
    _.forEach(res.data, function (value) {
      value.workingDate = getDateOnly(value.workingDate);
      value.dailyAVGSurplus = dailyAVGSurplus;
    });
  }
  return res;
}

export async function getWorkingDate() {
  let res = await http.get(apiEndpoint + "/GetWorkingDate");
  if (res) {
    res.data.workingDate = getDateOnly(res.data.workingDate);
  }

  return res;
}

export async function getNonClosedShifts(workingDate) {
  let res = await http.get(
    apiEndpoint + "/GetNonClosedShifts/" + getDateOnly(workingDate)
  );
  return res;
}

export async function getNotClosedBalanceSheets() {
  let res = await http.get(apiEndpoint + "/GetNotClosedBalanceSheets");
  if (res) {
    _.forEach(res.data, function (value) {
      value.workingDate = getDateOnly(value.workingDate);
    });
  }
  return res;
}

export async function getBalanceSheetsDateNotDeposit() {
  let res = await http.get(apiEndpoint + "/GetBalanceSheetsDateNotDeposit");
  if (res) {
    _.forEach(res.data, function (value) {
      value.date = getDateOnly(value.date);
    });
  }
  return res;
}

export async function getLastWeeklySales() {
  let res = await http.get(apiEndpoint + "/GetLastWeeklySales");
  return res;
}

export async function getLastMonthlySales() {
  let res = await http.get(apiEndpoint + "/GetLastMonthlySales");
  return res;
}

export function saveBalanceSheet(BalanceSheetData) {
  const body = { ...BalanceSheetData };
  if (body.comments === "") body.comments = null;
  if (body.actualTotalCasherBoxCash === "")
    body.actualTotalCasherBoxCash = null;
  if (body.actualTotalExpenses === "") body.actualTotalExpenses = null;
  if (body.actualTotalCards === "") body.actualTotalCards = null;
  if (body.systemTotalCash === "") body.systemTotalCash = null;
  if (body.systemTotalCards === "") body.systemTotalCards = null;
  if (body.systemTotalReturned === "") body.systemTotalReturned = null;
  if (body.POSSN === "") body.POSSN = null;
  if (body.attachmentsPath === "") body.attachmentsPath = null;
  return http.post(apiEndpoint, body);
}

export function uploadBalanceSheetFile(fileData) {
  const formData = new FormData();
  const date = new Date();
  let { branchID } = getCurrentUser();
  let filename;
  filename = getDateOnly(date);
  filename += "-" + branchID + "-" + makeId(6);
  filename += ".pdf";
  formData.append("file", fileData, filename);
  return http.post(apiEndpoint + "/UploadBalanceSheetFile", formData);
}
