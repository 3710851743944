import React, { useState, useEffect } from "react";
import LastChart from "./components/LastChart";
import StatisticsData3 from "./components/StatisticsData3";
import {
  getLastSurplus,
  getStatisticsSurplus,
  getStatisticsSales,
  getStatisticsExpenses,
  getStatisticsReturned,
  getLastSalesExpensesReturned,
  getStatisticsDeficit,
  getLastDeficit,
  getLastWeeklySales,
  getLastMonthlySales,
} from "./services/balanceSheetsService";

function Summary() {
  const [lastSurplusData, setLastSurplusData] = useState([]);
  const [statisticsSurplusData, setStatisticsSurplusData] = useState({});
  const [statisticsSalesData, setStatisticsSalesData] = useState({});
  const [statisticsExpensesData, setStatisticsExpensesData] = useState({});
  const [statisticsReturnedData, setStatisticsReturnedData] = useState({});
  const [lastSalesExpensesReturnedData, setLastSalesExpensesReturnedData] =
    useState([]);
  const [statisticsDeficitData, setStatisticsDeficitData] = useState({});

  const [lastDeficitData, setLastDeficitData] = useState([]);

  const [lastWeeklySales, setLastWeeklySales] = useState([]);
  const [lastMonthlySales, setLastMonthlySales] = useState([]);

  useEffect(() => {
    const fetchSalesExpensesReturned = async () => {
      try {
        const dailyAVGSales = await fetchStatisticsSales();
        const dailyAVGExpenses = await fetchStatisticsExpenses();
        const dailyAVGReturned = await fetchStatisticsReturned();
        fetchLastSalesExpensesReturned(
          dailyAVGSales,
          dailyAVGExpenses,
          dailyAVGReturned
        );
        fetchLastWeeklySales();
        fetchLasMonthlySales();
      } catch (ex) {
        console.log(ex); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        if (
          ex.response &&
          (ex.response.status === 401 || ex.response.status === 403)
        ) {
          window.location = "/login";
        }
      }
    };

    const fetchSurplus = async () => {
      try {
        const dailyAVGSurplus = await fetchStatisticsSurplus();
        fetchLastSurplus(dailyAVGSurplus);
      } catch (err) {
        console.log(err); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      }
    };

    const fetchDeficit = async () => {
      try {
        const dailyAVGDeficit = await fetchStatisticsDeficit();
        fetchLasDeficit(dailyAVGDeficit);
      } catch (err) {
        console.log(err); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      }
    };

    async function fetchStatisticsSales() {
      const { data } = await getStatisticsSales();
      setStatisticsSalesData(data);
      return data.dailyAVG;
    }

    async function fetchStatisticsExpenses() {
      const { data } = await getStatisticsExpenses();
      setStatisticsExpensesData(data);
      return data.dailyAVG;
    }

    async function fetchStatisticsReturned() {
      const { data } = await getStatisticsReturned();
      setStatisticsReturnedData(data);
      return data.dailyAVG;
    }

    async function fetchLastSalesExpensesReturned(
      dailyAVGSales,
      dailyAVGExpenses,
      dailyAVGReturned
    ) {
      const { data } = await getLastSalesExpensesReturned(
        dailyAVGSales,
        dailyAVGExpenses,
        dailyAVGReturned
      );
      setLastSalesExpensesReturnedData(data);
    }
    //kkkkkkkkkkkkkkkkkkkkkkkk
    async function fetchLastWeeklySales() {
      const { data } = await getLastWeeklySales();
      setLastWeeklySales(data);
    }

    async function fetchLasMonthlySales() {
      const { data } = await getLastMonthlySales();
      setLastMonthlySales(data);
    }
    async function fetchStatisticsSurplus() {
      const { data } = await getStatisticsSurplus();
      setStatisticsSurplusData(data);
      return data.dailyAVG;
    }

    async function fetchLastSurplus(dailyAVGSurplus) {
      const { data } = await getLastSurplus(dailyAVGSurplus);
      setLastSurplusData(data);
    }

    async function fetchStatisticsDeficit() {
      const { data } = await getStatisticsDeficit();
      setStatisticsDeficitData(data);
      return data.dailyAVG;
    }

    async function fetchLasDeficit(dailyAVGDeficit) {
      const { data } = await getLastDeficit(dailyAVGDeficit);
      setLastDeficitData(data);
    }
    fetchSalesExpensesReturned();
    fetchSurplus();
    fetchDeficit();
  }, []);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-4 mt-2">
            <StatisticsData3
              title="المبيعات اليومي"
              titleColor="text-success"
              max={statisticsSalesData.dailyMAX}
              avg={statisticsSalesData.dailyAVG}
              min={statisticsSalesData.dailyMIN}
              maxDate={statisticsSalesData.dailyMAXDate}
              minDate={statisticsSalesData.dailyMINDate}
              colorOrder={true}
            ></StatisticsData3>
          </div>
          <div className="col-sm-4 mt-2">
            <StatisticsData3
              title="المبيعات الاسبوعية"
              titleColor="text-success"
              max={statisticsSalesData.weeklyMAX}
              avg={statisticsSalesData.weeklyAVG}
              min={statisticsSalesData.weeklyMIN}
              maxDate={statisticsSalesData.weeklyMAXDate}
              minDate={statisticsSalesData.weeklyMINDate}
              colorOrder={true}
            ></StatisticsData3>
          </div>
          <div className="col-sm-4 mt-2">
            <StatisticsData3
              title="المبيعات الشهرية"
              titleColor="text-success"
              max={statisticsSalesData.monthlyMAX}
              avg={statisticsSalesData.monthlyAVG}
              min={statisticsSalesData.monthlyMIN}
              maxDate={statisticsSalesData.monthlyMAXDate}
              minDate={statisticsSalesData.monthlyMINDate}
              colorOrder={true}
            ></StatisticsData3>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-4  mt-2">
            <StatisticsData3
              title="المصروفات اليومي"
              titleColor="text-danger"
              max={statisticsExpensesData.dailyMAX}
              avg={statisticsExpensesData.dailyAVG}
              min={statisticsExpensesData.dailyMIN}
              maxDate={statisticsExpensesData.dailyMAXDate}
              minDate={statisticsExpensesData.dailyMINDate}
              colorOrder={false}
            ></StatisticsData3>
          </div>
          <div className="col-sm-4  mt-2">
            <StatisticsData3
              title="المصروفات الاسبوعية"
              titleColor="text-danger"
              max={statisticsExpensesData.weeklyMAX}
              avg={statisticsExpensesData.weeklyAVG}
              min={statisticsExpensesData.weeklyMIN}
              maxDate={statisticsExpensesData.weeklyMAXDate}
              minDate={statisticsExpensesData.weeklyMINDate}
              colorOrder={false}
            ></StatisticsData3>
          </div>
          <div className="col-sm-4  mt-2">
            <StatisticsData3
              title="المصروفات الشهرية"
              titleColor="text-danger"
              max={statisticsExpensesData.monthlyMAX}
              avg={statisticsExpensesData.monthlyAVG}
              min={statisticsExpensesData.monthlyMIN}
              maxDate={statisticsSalesData.monthlyMAXDate}
              minDate={statisticsSalesData.monthlyMINDate}
              colorOrder={false}
            ></StatisticsData3>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-4  mt-2">
            <StatisticsData3
              title="المسترجع "
              titleColor="text-warning"
              max={statisticsReturnedData.dailyMAX}
              avg={statisticsReturnedData.dailyAVG}
              min={statisticsReturnedData.dailyMIN}
              maxDate={statisticsReturnedData.dailyMAXDate}
              minDate={statisticsReturnedData.dailyMINDate}
              colorOrder={false}
            ></StatisticsData3>
          </div>
          <div className="col-sm-4  mt-2">
            <StatisticsData3
              title="الفائض"
              titleColor="text-warning"
              max={statisticsSurplusData.dailyMAX}
              avg={statisticsSurplusData.dailyAVG}
              min={statisticsSurplusData.dailyMIN}
              maxDate={statisticsSurplusData.dailyMAXDate}
              minDate={statisticsSurplusData.dailyMINDate}
              colorOrder={false}
            ></StatisticsData3>
          </div>
          <div className="col-sm-4  mt-2">
            <StatisticsData3
              title="العجز"
              titleColor="text-danger"
              max={statisticsDeficitData.dailyMAX}
              avg={statisticsDeficitData.dailyAVG}
              min={statisticsDeficitData.dailyMIN}
              maxDate={statisticsDeficitData.dailyMAXDate}
              minDate={statisticsDeficitData.dailyMINDate}
              colorOrder={false}
            ></StatisticsData3>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 mt-2">
            <LastChart
              title="المبيعات اخر 10 ايام"
              titleColor="text-success"
              data={lastSalesExpensesReturnedData}
              xAxisLable="workingDate"
              areaDataKey="actualTotalSales"
              areaName="المبيعات اليومية"
              areaColor="#28a745"
              lineDataKey="dailyAVGSales"
              lineName="متوسط"
            />
          </div>
          <div className="col-sm-6 mt-2">
            <LastChart
              title="المبيعات الاسبوعية"
              titleColor="text-success"
              data={lastWeeklySales}
              xAxisLable="workingDateWeek"
              areaDataKey="actualTotalSales"
              areaName="المبيعات الاسبوعية"
              areaColor="#28a745"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 mt-2">
            <LastChart
              title="المبيعات الشهرية"
              titleColor="text-success"
              data={lastMonthlySales}
              xAxisLable="workingDateMonth"
              areaDataKey="actualTotalSales"
              areaName="المبيعات الشهرية"
              areaColor="#28a745"
            />
          </div>
          <div className="col-sm-6 mt-2"></div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 mt-2">
            <LastChart
              title="المصروفات اخر 10 ايام"
              titleColor="text-danger"
              data={lastSalesExpensesReturnedData}
              xAxisLable="workingDate"
              areaDataKey="actualTotalExpenses"
              areaName="المصروفات"
              areaColor="#dc3545"
              lineDataKey="dailyAVGExpenses"
              lineName="متوسط"
            />
          </div>

          <div className="col-sm-6 mt-2">
            <LastChart
              title="العجز اخر 10 ايام"
              titleColor="text-danger"
              data={lastDeficitData}
              xAxisLable="workingDate"
              areaDataKey="deficit"
              areaName="العجز"
              areaColor="#dc3545"
              lineDataKey="dailyAVGDeficit"
              lineName="متوسط"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 mt-2">
            <LastChart
              title="المسترجع اخر 10 ايام"
              titleColor="text-warning"
              data={lastSalesExpensesReturnedData}
              xAxisLable="workingDate"
              areaDataKey="systemTotalReturned"
              areaName="المسترجع"
              areaColor="#ffc107"
              lineDataKey="dailyAVGReturned"
              lineName="متوسط"
            />
          </div>
          <div className="col-sm-6 mt-2">
            <LastChart
              title="فائض اخر 10 ايام"
              titleColor="text-warning"
              data={lastSurplusData}
              xAxisLable="workingDate"
              areaDataKey="surplus"
              areaName="الفائض"
              areaColor="#ffc107"
              lineDataKey="dailyAVGSurplus"
              lineName="متوسط"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Summary;
