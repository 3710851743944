import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

import {
  getFormDetails,
  saveForm,
  uploadDepositFile,
} from "./services/formsService";
import Loading from "./components/Loading";
import WorkingDateNotDeposit from "./components/WorkingDateNotDeposit";
import { toast } from "react-toastify";

function Form() {
  const [formDetails, setFormDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [isDeposit, setIsDeposit] = useState(false);
  const [checked, setChecked] = useState(false);

  const [userData, setUserData] = useState({
    formID: id,
    approved: false,
    comments: "",
    totalDeposit: "",
    attachmentsPath: null,
    workingDateList: null,
  });

  const fetchFormDetails = async () => {
    setLoading(true);
    try {
      const { data } = await getFormDetails(id);
      setFormDetails(data);
      setIsDeposit(data.isDeposit);
      setLoading(false);
    } catch (ex) {
      setFormDetails({});
      console.log(ex); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      setLoading(false);
      setIsDeposit(false);
      if (
        ex.response &&
        (ex.response.status === 401 || ex.response.status === 403)
      ) {
        window.location = "/login";
      }
    }
  };

  useEffect(() => {
    fetchFormDetails();
  }, []);

  const handleChange = (event) => {
    setErrors({});
    const { name, value } = event.target;
    let xuserData = { ...userData };
    xuserData[name] = value;
    setUserData(xuserData);
  };

  const doSubmit = async (approved) => {
    let xuserData = { ...userData };
    xuserData["approved"] = approved;
    setUserData(xuserData);
    let errorsData = { ...errors };
    if (xuserData.comments === "" && !approved) {
      errorsData["comments"] = "Comments is required";
      setErrors(errorsData);
    } else {
      if (
        isDeposit &&
        approved &&
        (!xuserData.totalDeposit || xuserData.totalDeposit === "")
      ) {
        errorsData["totalDeposit"] =
          "Total deposit is required اجمالي الايداع مطلوب";
        setErrors(errorsData);
      } else {
        if (
          isDeposit &&
          approved &&
          (!xuserData.attachmentsPath || xuserData.attachmentsPath === null)
        ) {
          errorsData["attachmentsPath"] =
            "The deposit copy should be attached يجب ارفاق نسخه من الايداع ";
          setErrors(errorsData);
        } else {
          setErrors({});
          setLoading(true);
          try {
            const { data } = await saveForm(xuserData);
            toast.success(data);
            setLoading(false);
          } catch (ex) {
            console.log(ex); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
            setLoading(false);
            if (
              ex.response &&
              (ex.response.status === 401 || ex.response.status === 403)
            ) {
              window.location = "/login";
            }
            toast.error(ex.response.data);
          }
          fetchFormDetails();
        }
      }
    }
  };

  function setSelectedWorkingDateNotDepositList(
    SelectedWorkingDate,
    TotalDeposit
  ) {
    let xuserData = { ...userData };
    xuserData["workingDateList"] = SelectedWorkingDate;
    setUserData(xuserData);
    setChecked(true);
  }

  const attachmentUpload = async (e) => {
    e.preventDefault();
    let xuserData = { ...userData };
    try {
      const { data } = await uploadDepositFile(e.target.files[0]);

      xuserData["attachmentsPath"] = data.fileName;
    } catch (ex) {
      xuserData["attachmentsPath"] = null;
    }
    setUserData(xuserData);
  };
  return (
    <React.Fragment>
      <>
        <div className="container-fluid">
          <div className="row my-4 mx-2">
            <div className="col-sm-12">
              {/* <!-- Start Card  --> */}
              <div className="card">
                <h5 className="card-header fw-bold">
                  <Loading loading={loading}></Loading>
                  {formDetails.formName}
                  <span className="badge text-bg-danger m-2">
                    {formDetails.formTime}
                  </span>
                </h5>
                <div className="card-body">
                  <div className="form-group m-2">
                    {formDetails.isFormOpen ? (
                      <>
                        <div className="row mt-4 mb-2">
                          <div className="col-lg-12">
                            <div className="row">
                              {formDetails.htmlDescription &&
                                parse(formDetails.htmlDescription)}
                            </div>
                          </div>
                        </div>

                        {isDeposit && (
                          <>
                            <div className="row mt-4 mb-2">
                              <div className="col-lg-12">
                                <div className="row">
                                  <WorkingDateNotDeposit
                                    onChangeSelectedWorkingDateNotDepositList={
                                      setSelectedWorkingDateNotDepositList
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <hr className="my-4" />
                            <div className="row mt-4 mb-2">
                              <div className="col-lg-6">
                                <div className="row">
                                  <label
                                    htmlFor="totalDeposit"
                                    className="form-label fw-bold text-primary"
                                  >
                                    مبلغ الايداع
                                  </label>
                                  <input
                                    id="totalDeposit"
                                    name="totalDeposit"
                                    type="number"
                                    className="form-control"
                                    value={userData.totalDeposit}
                                    onChange={handleChange}
                                    disabled={
                                      !(!isDeposit || (isDeposit && checked))
                                    }
                                  />
                                  {errors.totalDeposit && (
                                    <div
                                      className="alert alert-danger m-1"
                                      role="alert"
                                    >
                                      {errors.totalDeposit}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="row">
                                  {" "}
                                  <label
                                    htmlFor="AttachmentUpload"
                                    className="form-label fw-bold text-primary"
                                  >
                                    ارفاق ايصال الايداع
                                  </label>
                                  <input
                                    id="AttachmentUpload"
                                    type="file"
                                    className="form-control"
                                    accept=".pdf"
                                    onChange={attachmentUpload}
                                    disabled={
                                      !(!isDeposit || (isDeposit && checked))
                                    }
                                  />
                                  {errors.attachmentsPath && (
                                    <div
                                      className="alert alert-danger m-1"
                                      role="alert"
                                    >
                                      {errors.attachmentsPath}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <hr className="my-4" />
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <label
                                htmlFor="comments"
                                className="form-label fw-bold text-primary"
                              >
                                Comments
                              </label>
                              <input
                                type="text"
                                name="comments"
                                id="comments"
                                placeholder="Comments"
                                className="form-control"
                                value={userData.comments}
                                onChange={handleChange}
                              />
                              {errors.comments && (
                                <div
                                  className="alert alert-danger m-1"
                                  role="alert"
                                >
                                  {errors.comments}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-lg-5 mt-4 mb-2">
                            <div className="row">
                              <button
                                type="button"
                                className="btn btn-success btn-lg"
                                onClick={() => doSubmit(true)}
                                disabled={
                                  !(!isDeposit || (isDeposit && checked))
                                }
                              >
                                Approved
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-2 mt-4 mb-2 ">
                            <div className="row"></div>
                          </div>

                          <div className="col-lg-5 mt-4 mb-2">
                            <div className="row">
                              <button
                                type="button"
                                className="btn btn-danger btn-lg"
                                onClick={() => doSubmit(false)}
                              >
                                Not Approved
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      // START The Form is Closed
                      // <!-- START Form is Closed callout -->

                      <div className="container-fluid">
                        <div className="bd-callout bd-callout-danger">
                          <h4> النوذج مغلق ! The form is closed already ! </h4>
                          <div className="row">
                            <div className="col-lg-12">
                              <p className="text-muted">
                                وقت فتح النموذج the from will open on :{" "}
                                {formDetails.formOpenDateTime}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <p className="text-muted">
                                اخر اقفال للنموذج بـ last closing for this form
                                on : {formDetails.maxFormDateTime}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      // End The Form is Closed
                      // <!-- START Form is Closed callout -->
                    )}
                  </div>
                </div>
              </div>
              {/* <!-- End Card  --> */}
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
export default Form;
