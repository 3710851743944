import React, { Component } from "react";
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

class LastChart extends Component {
  render() {
    const {
      title,
      data,
      xAxisLable,
      areaDataKey,
      areaName,
      lineDataKey,
      lineName,
      titleColor,
      areaColor,
    } = this.props;
    return (
      <div className="card m-2">
        <h5 className={`card-header fw-bold ${titleColor}`}>{title}</h5>
        <div className="card-body">
          <ResponsiveContainer width="99%" aspect={8 / 3}>
            <ComposedChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis dataKey={xAxisLable} tick={{ fontSize: 10 }} />
              <Tooltip />

              <Area
                type="monotone"
                dataKey={areaDataKey}
                stackId="1"
                stroke={areaColor}
                fill={areaColor}
                name={areaName}
              />
              <Line
                type="monotone"
                dataKey={lineDataKey}
                stroke="#8884d8"
                name={lineName}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default LastChart;
