import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function Topbar({ user }) {
  return (
    <header>
      <nav className="navbar navbar-dark navbar-custom navbar-expand-sm">
        <Link className="navbar-brand m-2" to="/">
          كيان المزاحمية
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            {!user && (
              <React.Fragment>
                <li className="nav-item">
                  <NavLink className="nav-item nav-link" to="/login">
                    Login
                  </NavLink>
                </li>
              </React.Fragment>
            )}
            {user && (
              <React.Fragment>
                <li className="nav-item">
                  <NavLink className="nav-item nav-link" to="/">
                    {user.unique_name}
                  </NavLink>
                </li>
                {/* Stat  user is Owner */}
                {user.userType === "Owner" && (
                  <React.Fragment>
                    <li className="nav-item">
                      <NavLink className="nav-item nav-link" to="/insert">
                        اضافة يومية
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-item nav-link"
                        to="/balanceSheets"
                      >
                        اليومية تفصيلي
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-item nav-link" to="/summary">
                        اليومية ملخص
                      </NavLink>
                    </li>
                  </React.Fragment>
                )}
                {/* end  user is Owner */}

                {/* Stat  user is Manager */}
                {user.userType === "Manager" && (
                  <React.Fragment>
                    <li className="nav-item">
                      <NavLink className="nav-item nav-link" to="/insert">
                        اضافة يومية
                      </NavLink>
                    </li>
                  </React.Fragment>
                )}
                {/* end  user is Manager */}

                {/* Stat  user is Accountant */}
                {user.userType === "Accountant" && (
                  <React.Fragment>
                    <li className="nav-item">
                      <NavLink
                        className="nav-item nav-link"
                        to="/balanceSheets"
                      >
                        اليومية تفصيلي
                      </NavLink>
                    </li>
                  </React.Fragment>
                )}
                {/* end  user is Accountant */}
                <li className="nav-item">
                  <NavLink className="nav-item nav-link" to="/formsList">
                    النماذج
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-item nav-link" to="/logout">
                    Logout
                  </NavLink>
                </li>
              </React.Fragment>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
}
