import React from "react";
import { commify } from "../utils/commify";

export default function StatisticsData3({
  title,
  titleColor,
  max,
  avg,
  min,
  maxDate,
  minDate,
  colorOrder = true,
}) {
  var maxstyle = "card-text text-success";
  var minstyle = "card-text text-danger";

  if (!colorOrder) {
    maxstyle = "card-text text-danger";
    minstyle = "card-text text-success";
  }
  return (
    <>
      {/* <!-- Start Card  --> */}
      <div className="card">
        <h5 className={`card-header fw-bold ${titleColor}`}>{title}</h5>
        <div className="card-body">
          <p className={maxstyle}>
            ▲ {commify(max)} &nbsp;&nbsp;
            <span className="text-muted">{maxDate}</span>
          </p>

          <p className="card-text text-warning">▬ &nbsp; {commify(avg)}</p>
          <p className={minstyle}>
            ▼ {commify(min)} &nbsp;&nbsp;
            <span className="text-muted">{minDate}</span>
          </p>
        </div>
      </div>
      {/* <!-- End Card  --> */}
    </>
  );
}
