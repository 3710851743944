import { format } from "date-fns";

export function getDateOnly(date) {
  date = format(new Date(date), "yyyy-MM-dd");
  return date;
}

export function getDateTime(date) {
  date = format(new Date(date), "yyyy-MM-dd hh:mm a");
  return date;
}
