import React from "react";
import { useEffect, useState } from "react";
import { getFormsList } from "./services/formsService";
import Loading from "./components/Loading";

export default function FormsList() {
  const [formsList, setFormsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFFormsList = async () => {
      setLoading(true);
      try {
        const { data } = await getFormsList();
        setFormsList(data);
        setLoading(false);
      } catch (ex) {
        setFormsList([]);
        console.log(ex); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        setLoading(false);
        if (
          ex.response &&
          (ex.response.status === 401 || ex.response.status === 403)
        ) {
          window.location = "/login";
        }
      }
    };

    fetchFFormsList();
  }, []);
  return (
    <React.Fragment>
      {/* START SHIFT DATA */}
      <div className="container-fluid mt-2">
        <div className="card">
          <div className="card-header py-4 px-5 bg-light border-0">
            <h4 className="mb-0 fw-bold">
              <Loading loading={loading}></Loading>
              قائمة النماذج
            </h4>
          </div>
          <div className="card-body">
            {formsList.map((form) => (
              <div className="row py-2">
                <div className="col-xs">
                  <div className="card">
                    <div className="card-body wrap">
                      <a href={"form/" + form.formID}></a>
                      {form.formName}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* END SHIFT DATA */}
    </React.Fragment>
  );
}
