import React from "react";

function Home() {
  return (
    <>
      <div className="container-fluid">
        <div className="row mx-1 my-4">
          <div className="col-sm-12">
            {/* <!-- Start Card  --> */}
            <div className="card">
              <div className="card-body">Weclome , Please use the menu</div>
            </div>
            {/* <!-- End Card  --> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
