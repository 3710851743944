import React from "react";

export default function Loading({ loading }) {
  return (
    loading && (
      <>
        <div className="spinner-border text-info" role="status"></div>
        <span>تحميل البيانات ...</span>
      </>
    )
  );
}
