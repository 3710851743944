export function removeMonthlyWeeklyProperty(data) {
  delete data.monthlyAVG;
  delete data.monthlyMAX;
  delete data.monthlyMAXDate;
  delete data.monthlyMIN;
  delete data.monthlyMINDate;
  delete data.weeklyAVG;
  delete data.weeklyMAX;
  delete data.weeklyMAXDate;
  delete data.weeklyMIN;
  delete data.weeklyMINDate;
  return data;
}
