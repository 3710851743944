import React from "react";
import { useEffect, useMemo, useState } from "react";
import { exportToExcel } from "./utils/exportToExcel";
import { getBalanceSheets } from "./services/balanceSheetsService";
import RenderTable from "./components/RenderTable";
import { variables } from "./config";
import Loading from "./components/Loading";

export default function BalanceSheets() {
  const [balanceSheets, setBalanceSheets] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchBalanceSheets = async () => {
      setLoading(true);
      try {
        const { data } = await getBalanceSheets();
        setBalanceSheets(data);
        setLoading(false);
      } catch (ex) {
        setBalanceSheets([]);
        console.log(ex); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        setLoading(false);
        if (
          ex.response &&
          (ex.response.status === 401 || ex.response.status === 403)
        ) {
          window.location = "/login";
        }
      }
    };
    fetchBalanceSheets();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "اليوم",
        accessor: "workingDate",
      },
      {
        Header: "رقم السجل",
        accessor: "balanceSheetID",
      },
      {
        Header: "رقم الشفت",
        accessor: "shiftID",
      },
      {
        Header: "الشفت",
        accessor: "shiftName",
      },
      {
        Header: "السنة",
        accessor: "workingDateYear",
      },
      {
        Header: "الشهر",
        accessor: "workingDateMonth",
      },
      {
        Header: "الاسبوع",
        accessor: "workingDateWeek",
      },
      {
        Header: "كاش الصندوق",
        accessor: "actualTotalCasherBoxCash",
      },
      {
        Header: "المصروفات",
        accessor: "actualTotalExpenses",
        aggregate: "sum",
        Aggregated: ({ value }) => `${value} (اجمالي)`,
      },
      {
        Header: "مدى",
        accessor: "actualTotalCards",
      },
      {
        Header: "كاش الصندوق",
        accessor: "actualCash",
      },
      {
        Header: "اجمالي مبيعات الصندوق",
        accessor: "actualTotalSales",
        aggregate: "sum",
        Aggregated: ({ value }) => `${value} (اجمالي)`,
      },
      {
        Header: "كاش النظام",
        accessor: "systemTotalCash",
      },
      {
        Header: "مدى النظام",
        accessor: "systemTotalCards",
      },
      {
        Header: "المسترجع",
        accessor: "systemTotalReturned",
      },
      {
        Header: "اجمالي مبيعات النظام",
        accessor: "systemTotalSales",
        aggregate: "sum",
        Aggregated: ({ value }) => `${value} (اجمالي)`,
      },
      {
        Header: "الفائض/العجز",
        accessor: "deficitSurplus",
        aggregate: "sum",
        Aggregated: ({ value }) => `${value} (اجمالي)`,
      },
      {
        Header: "اغلاق",
        accessor: (d) => (d.closed ? " مغلق" : " غير مغلق"),
      },
      {
        Header: "ملاحظات",
        accessor: "comments",
      },
      {
        Header: "تم ايداعه",
        accessor: (d) => (d.depositDone ? "تم الايداع" : " لم يودع"),
      },
      {
        Header: "المرفقات",
        accessor: (d) => (
          <a
            href={
              variables.DOCS_URL +
              "/" +
              d.branchID +
              "/BalanceSheets/" +
              d.attachmentsPath
            }
          >
            {d.attachmentsPath}
          </a>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Loading loading={loading}></Loading>
      <>
        <button
          className="btn btn-primary btn-sm m-2"
          onClick={() => {
            exportToExcel(balanceSheets, "Shift data", "Shift data");
          }}
        >
          تحميل ملف اكسل
        </button>
        <RenderTable
          columns={columns}
          data={balanceSheets}
          groupBy="workingDate"
        />
      </>
    </React.Fragment>
  );
}
