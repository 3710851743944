import http from "./httpService";
import { getCurrentUser } from "./authenticationService";
import { variables } from "../config.js";
import { getDateTime, getDateOnly } from "../utils/getDateTime";
import { makeId } from "../utils/makeId";
const apiEndpoint = variables.API_URL + "/Forms";

export async function getFormDetails(id) {
  let res = await http.get(apiEndpoint + "/GetFormDetails/" + id);
  res.data.formDateTime = getDateTime(res.data.formDateTime);
  res.data.maxFormDateTime = getDateTime(res.data.maxFormDateTime);
  res.data.formOpenDateTime = getDateTime(res.data.formOpenDateTime);
  res.data.isDeposit = res.data.formType === 9 ? true : false;
  return res;
}

export function saveForm(formData) {
  const body = { ...formData };
  if (body.totalDeposit === "") body.totalDeposit = null;
  if (body.comments === "") body.comments = null;

  return http.post(apiEndpoint + "/InsertActivity", body);
}

export function uploadDepositFile(fileData) {
  const formData = new FormData();
  const date = new Date();
  let { branchID } = getCurrentUser();
  let filename;
  filename = getDateOnly(date);
  filename += "-" + branchID + "-" + makeId(6);
  filename += ".pdf";
  formData.append("file", fileData, filename);
  return http.post(apiEndpoint + "/UploadDepositFile", formData);
}

export async function getFormsList() {
  let res = await http.get(apiEndpoint + "/GetFormsList");
  return res;
}
