import { useState, useEffect } from "react";
import Joi from "joi-browser";

export default function useValidate(iData, iCanSubmit, iAbortEarly) {
  const [errors, setErrors] = useState({});
  const [schema, setSchema] = useState({});
  const [abortEarly] = useState(iAbortEarly);
  const [canSubmit, setCanSubmit] = useState(iCanSubmit);
  const [data, setData] = useState(iData);

  useEffect(() => {
    const result = Joi.validate(data, schema, { abortEarly: abortEarly });
    const { error } = result;
    if (!error) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [data, schema, abortEarly]);

  function validate(data) {
    const result = Joi.validate(data, schema, { abortEarly: abortEarly });
    const { error } = result;
    if (!error) {
      setErrors({});
      return true;
    } else {
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
      }
      setErrors(errorData);
      return false;
    }
  }

  const validateProperty = (event, schema) => {
    const { name, value } = event.target;
    const obj = { [name]: value };
    const subSchema = { [name]: schema[name] };
    const result = Joi.validate(obj, subSchema);
    const { error } = result;
    return error ? error.details[0].message : null;
  };

  const onChange = (event) => {
    const { name } = event.target;
    let errorData = { ...errors };
    const errorMessage = validateProperty(event, schema);
    if (errorMessage) {
      errorData[name] = errorMessage;
    } else {
      delete errorData[name];
    }
    setErrors(errorData);
  };

  return [data, errors, canSubmit, setData, setSchema, validate, onChange];
}
