import React, { useState, useEffect, useMemo } from "react";
import Joi from "joi-browser";
import { commify } from "./utils/commify";
import useValidate from "./hooks/useValidate";
import { variables } from "./config.js";
import Loading from "./components/Loading";
import { isEmpty } from "./utils/isEmpty";
import {
  getWorkingDate,
  getNonClosedShifts,
  getNotClosedBalanceSheets,
  saveBalanceSheet,
  uploadBalanceSheetFile,
} from "./services/balanceSheetsService";
export default function Insert({ user }) {
  const [
    userData,
    errors,
    canSubmit,
    setUserData,
    setSchema,
    validate,
    onChange,
  ] = useValidate(
    {
      shiftID: 0,
      actualTotalCasherBoxCash: "",
      actualTotalExpenses: "",
      actualTotalCards: "",
      systemTotalCash: "",
      systemTotalCards: "",
      systemTotalReturned: "",
      POSSN: "",
      comments: "",
    },
    false,
    false
  );
  const [workingDateData, setWorkingDate] = useState({});
  const [shiftsData, setShiftsData] = useState([]);
  const [notClosedBalanceSheetsData, setNotClosedBalanceSheetsData] = useState(
    []
  );
  const [results, setResults] = useState({});
  const [attachmentsPath, setAttachmentsPath] = useState("");
  const [cansend, setCanSend] = useState(false);
  const [loading, setLoading] = useState(true);
  const [insertError, setInsertError] = useState({});

  useEffect(() => {
    setCanSend(false);
  }, [userData]);

  useEffect(() => {
    setAttachmentsPath("");
    setInsertError({});
  }, [cansend]);

  useEffect(() => {
    const fetchWorkingDate = async () => {
      setLoading(true);
      try {
        const { data: workingDate } = await getWorkingDate();
        setWorkingDate(workingDate);

        const { data: shiftsList } = await getNonClosedShifts(
          workingDate.workingDate
        );
        setShiftsData(shiftsList);
        const { data: notClosedBalanceSheets } =
          await getNotClosedBalanceSheets();
        setNotClosedBalanceSheetsData(notClosedBalanceSheets);
        setLoading(false);
      } catch (ex) {
        setWorkingDate({});
        console.log(ex); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        setLoading(false);
        if (
          ex.response &&
          (ex.response.status === 401 || ex.response.status === 403)
        ) {
          window.location = "/login";
        }
      }
    };
    fetchWorkingDate();
  }, []);

  const schema = useMemo(() => {
    return {
      shiftID: Joi.number()
        .max(1000000)
        .min(1)
        .required()
        .error(() => {
          return {
            message: "Please select the shift",
          };
        }),
      actualTotalCasherBoxCash: Joi.number()
        .max(99999)
        .min(0)
        .required()
        .error(() => {
          return {
            message: "Please enter currect number between 0 - 99,999",
          };
        }),
      actualTotalExpenses: Joi.number()
        .max(99999)
        .min(0)
        .required()
        .error(() => {
          return {
            message: "Please enter currect number between 0 - 99,999",
          };
        }),

      actualTotalCards: Joi.number()
        .max(99999)
        .min(0)
        .required()
        .error(() => {
          return {
            message: "Please enter currect number between 0 - 99,999",
          };
        }),

      systemTotalCash: Joi.number()
        .max(99999)
        .min(0)
        .required()
        .error(() => {
          return {
            message: "Please enter currect number between 0 - 99,999",
          };
        }),

      systemTotalCards: Joi.number()
        .max(99999)
        .min(0)
        .required()
        .error(() => {
          return {
            message: "Please enter currect number between 0 - 99,999",
          };
        }),

      systemTotalReturned: Joi.number()
        .max(99999)
        .min(0)
        .required()
        .error(() => {
          return {
            message: "Please enter currect number between 0 - 99,999",
          };
        }),

      POSSN: Joi.string()
        .min(1)
        .max(60)
        .required()
        .error(() => {
          return {
            message: "Please Enter Correct SN ",
          };
        }),
      comments: Joi.string().allow("", null),
    };
  }, []);

  useEffect(() => {
    setSchema(schema);
  }, [schema, setSchema]);

  const handleSubmit = async (event) => {
    setInsertError({});
    if (validate(userData)) {
      try {
        let xuserData = { ...userData };
        xuserData["attachmentsPath"] = attachmentsPath;
        xuserData["workingDate"] = workingDateData.workingDate;
        console.log(xuserData["workingDate"]);
        await saveBalanceSheet(xuserData);
        window.location = "/insert";
      } catch (ex) {
        console.log(ex); //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        if (ex.response) {
          const insertErrorData = { ...insertError };
          insertErrorData.msg = variables.INSERT_BALANCESHEET_ERROR_MSG;
          setInsertError(insertErrorData);
        }
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let xuserData = { ...userData };
    xuserData[name] = value;
    setUserData(xuserData);
    onChange(event);
  };

  const calculate = () => {
    setResults({});
    if (
      userData.shiftID !== "" &&
      userData.shiftID !== "0" &&
      !isEmpty(userData.actualTotalCasherBoxCash) &&
      !isEmpty(userData.actualTotalExpenses) &&
      !isEmpty(userData.actualTotalCards) &&
      !isEmpty(userData.systemTotalCash) &&
      !isEmpty(userData.systemTotalCards) &&
      !isEmpty(userData.systemTotalReturned)
    ) {
      let DeficitSurplus =
        parseFloat(userData.actualTotalCasherBoxCash) +
        parseFloat(userData.actualTotalExpenses) +
        parseFloat(userData.actualTotalCards) -
        (parseFloat(userData.systemTotalCash) +
          parseFloat(userData.systemTotalCards) -
          parseFloat(userData.systemTotalReturned));
      DeficitSurplus = DeficitSurplus.toFixed(2);
      const actualCash =
        parseFloat(userData.actualTotalCasherBoxCash) +
        parseFloat(userData.actualTotalExpenses);
      const actualTotalSales =
        parseFloat(userData.actualTotalCasherBoxCash) +
        parseFloat(userData.actualTotalExpenses) +
        parseFloat(userData.actualTotalCards);
      const systemTotalSales =
        parseFloat(userData.systemTotalCash) +
        parseFloat(userData.systemTotalCards) -
        parseFloat(userData.systemTotalReturned);
      let xresults = { ...results };
      xresults["actualCash"] = actualCash;
      xresults["actualTotalSales"] = actualTotalSales;
      xresults["systemTotalSales"] = systemTotalSales;
      xresults["deficitSurplus"] = DeficitSurplus;
      setResults(xresults);
      setCanSend(true);
    } else {
      setCanSend(false);
      alert("تأكد من اختيار الشفت ، وكذلك جميع المعلومات");
    }
  };

  const attachmentUpload = async (e) => {
    e.preventDefault();
    setInsertError({});
    try {
      const { data } = await uploadBalanceSheetFile(e.target.files[0]);
      setAttachmentsPath(data.fileName);
    } catch (ex) {
      setAttachmentsPath("");
      const insertErrorData = { ...insertError };
      insertErrorData.msg = variables.UPLOAD_FILE_ERROR_MSG;
      setInsertError(insertErrorData);
    }
  };
  return (
    <>
      {/* START SHIFT DATA */}
      <div className="container-fluid mt-2">
        <div className="card">
          <div className="card-header py-4 px-5 bg-light border-0">
            <h4 className="mb-0 fw-bold">
              <Loading loading={loading}></Loading>
              أضافة شفت ليوم{" "}
              <span className="badge text-bg-danger">
                {" "}
                {workingDateData && <>{workingDateData.workingDate}</>}{" "}
              </span>
            </h4>
          </div>
          <div className="card-body px-5">
            {/* <!-- START Shift section --> */}
            <div className="row">
              <div className="col-lg-4">
                <label
                  htmlFor="shiftID"
                  className="form-label fw-bold text-info"
                >
                  اختر الشفت
                </label>

                <select
                  className="form-select"
                  name="shiftID"
                  id="shiftID"
                  onChange={handleChange}
                  value={userData.shiftID}
                >
                  <option key="0" value="0">
                    أختر الشفت
                  </option>

                  {shiftsData.map((shift) => (
                    <option key={shift.shiftID} value={shift.shiftID}>
                      {shift.name}
                    </option>
                  ))}
                </select>
                {errors.shiftID && (
                  <div className="alert alert-danger m-1" role="alert">
                    {errors.shiftID}
                  </div>
                )}
              </div>
              <div className="col-lg-8"></div>
            </div>
            {/* <!-- END Shift section --> */}
            <hr className="my-5" />
            {/* <!--  START Casher Box section --> */}
            <div className="row">
              <div className="col-lg-4">
                <label
                  htmlFor="actualTotalCasherBoxCash"
                  className="form-label fw-bold text-primary"
                >
                  كاش الصندوق
                </label>
                <input
                  id=""
                  name="actualTotalCasherBoxCash"
                  className="form-control"
                  type="number"
                  onChange={handleChange}
                  value={userData.actualTotalCasherBoxCash}
                />
                {errors.actualTotalCasherBoxCash && (
                  <div className="alert alert-danger m-1" role="alert">
                    {errors.actualTotalCasherBoxCash}
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="actualTotalExpenses"
                  className="form-label fw-bold text-primary"
                >
                  مصروفات الصندوق
                </label>
                <input
                  id="actualTotalExpenses"
                  name="actualTotalExpenses"
                  className="form-control"
                  type="number"
                  onChange={handleChange}
                  value={userData.actualTotalExpenses}
                />
                {errors.actualTotalExpenses && (
                  <div className="alert alert-danger m-1" role="alert">
                    {errors.actualTotalExpenses}
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="actualTotalCards"
                  className="form-label fw-bold text-primary"
                >
                  مدى الصندوق
                </label>
                <input
                  id="actualTotalCards"
                  name="actualTotalCards"
                  className="form-control"
                  type="number"
                  onChange={handleChange}
                  value={userData.actualTotalCards}
                />
                {errors.actualTotalCards && (
                  <div className="alert alert-danger m-1" role="alert">
                    {errors.actualTotalCards}
                  </div>
                )}
              </div>
            </div>
            {/* <!--  END Casher Box section --> */}
            <hr className="my-5" />
            {/* <!--  START System section --> */}
            <div className="row">
              <div className="col-lg-4">
                <label
                  htmlFor="systemTotalCash"
                  className="form-label fw-bold text-warning"
                >
                  كاش النظام
                </label>
                <input
                  id="systemTotalCash"
                  name="systemTotalCash"
                  className="form-control"
                  type="number"
                  onChange={handleChange}
                  value={userData.systemTotalCash}
                />
                {errors.systemTotalCash && (
                  <div className="alert alert-danger m-1" role="alert">
                    {errors.systemTotalCash}
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="systemTotalCards"
                  className="form-label fw-bold text-warning"
                >
                  مدى النظام
                </label>
                <input
                  id="systemTotalCards"
                  name="systemTotalCards"
                  className="form-control"
                  type="number"
                  onChange={handleChange}
                  value={userData.systemTotalCards}
                />
                {errors.systemTotalCards && (
                  <div className="alert alert-danger m-1" role="alert">
                    {errors.systemTotalCards}
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="systemTotalReturned"
                  className="form-label fw-bold text-warning"
                >
                  المسترجع النظام
                </label>
                <input
                  id="systemTotalReturned"
                  name="systemTotalReturned"
                  className="form-control"
                  type="number"
                  onChange={handleChange}
                  value={userData.systemTotalReturned}
                />
                {errors.systemTotalReturned && (
                  <div className="alert alert-danger m-1" role="alert">
                    {errors.systemTotalReturned}
                  </div>
                )}
              </div>
            </div>
            {/* <!--  END System section --> */}
            <hr className="my-5" />

            {/* <!--  START Comments POSSN section --> */}
            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="POSSN" className="form-label fw-bold ">
                  الرقم التسلسلي لاجهزة مدى
                </label>
                <input
                  id="POSSN"
                  name="POSSN"
                  className="form-control"
                  type="text"
                  onChange={handleChange}
                  value={userData.POSSN}
                />
                {errors.POSSN && (
                  <div className="alert alert-danger m-1" role="alert">
                    {errors.POSSN}
                  </div>
                )}
              </div>
              <div className="col-lg-8">
                <label htmlFor="comments" className="form-label fw-bold">
                  الملاحظات
                </label>
                <input
                  id="comments"
                  name="comments"
                  className="form-control"
                  type="text"
                  onChange={handleChange}
                  value={userData.comments}
                />
                {errors.comments && (
                  <div className="alert alert-danger m-1" role="alert">
                    {errors.comments}
                  </div>
                )}
              </div>
            </div>
            {/* <!--  END Comments POSSN  section --> */}
            {cansend ? (
              <>
                {/* <!-- START calculate Result --> */}
                <hr className="my-5" />
                <div className="container-fluid">
                  <div className="bd-callout bd-callout-warning">
                    <h4>النتيجة</h4>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col"> كاش الصندوق</th>
                          <th scope="col"> مبيعات الصندوق</th>
                          <th scope="col"> مبيعات النظام</th>
                          <th scope="col"> العجز / الفائض</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <p className="text-primary fw-bold">
                              {commify(results.actualCash)}
                            </p>
                          </td>
                          <td>
                            <p className="text-primary fw-bold">
                              {commify(results.actualTotalSales)}
                            </p>
                          </td>
                          <td>
                            <p className="text-primary fw-bold">
                              {commify(results.systemTotalSales)}
                            </p>
                          </td>
                          <td>
                            <p className="text-primary fw-bold">
                              {commify(results.deficitSurplus)}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <!-- END calculate Result  --> */}

                {/* <!-- START attachment Upload section --> */}
                <div className="row">
                  <div className="col-lg-6">
                    <label
                      htmlFor="attachmentUpload"
                      className="form-label fw-bold text-primary"
                    >
                      ارفاق الملف
                    </label>
                    <input
                      id="attachmentUpload"
                      type="file"
                      className="form-control"
                      accept=".pdf"
                      onChange={attachmentUpload}
                      /* disabled={!(!isDeposit || (isDeposit && checked))} */
                    />
                  </div>
                  <div className="col-lg-6"></div>
                </div>
                {/* <!-- END attachment Upload  section --> */}

                {/* <!-- Start  submit section --> */}
                <hr className="my-5" />
                <div className="card-footer text-end py-4 px-5 bg-transparent border-0">
                  {insertError.msg && (
                    <div className="alert alert-danger m-2" role="alert">
                      {insertError.msg}
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn btn-success btn-lg"
                    onClick={handleSubmit}
                    disabled={attachmentsPath === ""}
                  >
                    اضافة الشفت
                  </button>
                </div>
                {/* <!-- END Upload submit section --> */}
              </>
            ) : (
              <>
                <hr className="my-5" />
                {/* <!--  START   calculate button section --> */}
                <div className="card-footer text-end py-4 px-5 bg-transparent  border-0">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg"
                    disabled={!canSubmit}
                    onClick={calculate}
                  >
                    احسب
                  </button>
                </div>
                {/* <!--  END   calculate button section --> */}
              </>
            )}
          </div>
        </div>
      </div>

      {/* END SHIFT DATA */}

      {/* START LIST OF CLOSED SHIFT */}
      <div className="container-fluid mt-2">
        <div className="card">
          <div className="card-header py-4 px-5 bg-light border-0">
            <h4 className="mb-0 fw-bold">
              الشفتات المغلقة ليوم{" "}
              <span className="badge text-bg-info">
                {" "}
                {workingDateData && <>{workingDateData.workingDate}</>}{" "}
              </span>
            </h4>
          </div>
          <div className="card-body px-5">
            {notClosedBalanceSheetsData.map((BS) => (
              <div className="container-fluid" key={BS.balanceSheetID}>
                <div className="bd-callout bd-callout-success">
                  <h4> شفت : {BS.shiftName} </h4>

                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        {" "}
                        بداية الشفت : {BS.startTime}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        كاش الصندوق : {commify(BS.actualTotalCasherBoxCash)}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        المصروفات : {commify(BS.actualTotalExpenses)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        مجموع مدى : {commify(BS.actualTotalCards)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        {" "}
                        مجموع كاش الصندوق : {commify(BS.actualCash)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        مبيعات الصندوق : {commify(BS.actualTotalSales)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        كاش النظام : {commify(BS.systemTotalCash)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        مدى النظام : {commify(BS.systemTotalCards)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        المبلغ المسترجع : {commify(BS.systemTotalReturned)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        مبيعات النظام : {commify(BS.systemTotalSales)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        العجز الفائض : {commify(BS.deficitSurplus)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">اجهزة مدى : {BS.possn}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted"> ملاحظات : {BS.comments}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="text-muted">
                        {" "}
                        المرفقات :{" "}
                        <a
                          href={
                            variables.DOCS_URL +
                            "/" +
                            user.branchID +
                            "/BalanceSheets/" +
                            BS.attachmentsPath
                          }
                        >
                          {BS.attachmentsPath}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* END LIST OF CLOSED SHIFT */}
    </>
  );
}
